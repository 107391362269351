import React, { useEffect, useRef, useState } from 'react';
import FrontendHeader from './FrontendHeader';
import style from '../CSS/Style.module.css';
import { toJpeg } from 'html-to-image';
import axios from 'axios';
import Heart from 'react-animated-heart';
export default function HomePage() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [quote,setQuote]=useState('')
    const [flipped, setFlipped] = useState(false);
    const [lastFetchDate, setLastFetchDate] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [isClick, setClick] = useState(false);
const [liked, setLiked] = useState(false)
    const divRef = useRef(null);


    useEffect(()=>{
        fetchRandomQuote();
    },[]);
const fetchRandomQuote = async () =>{
    try{
        const res =await axios.get(`${apiUrl}/randomQuote1`)
        setQuote(res.data)
        setLastFetchDate(new Date().toISOString().slice(0, 10));
    }catch(error)
    {
        console.error("error fetching random quote:",error)
    }
}
const handleFlip = () => {
    if (!flipped) {
      setFlipped(true);
      
    }else{
        alert("Already flipped, Your next flip can be done tomorrow!")
    }
  };

    const handleDownload = () => {
        const divElement = divRef.current;
        if (!divElement) {
            console.error('Ref is null');
            return;
        }

        toJpeg(divElement)
            .then(function (dataUrl) {
                const downloadLink = document.createElement('a');
                downloadLink.href = dataUrl;
                downloadLink.download = 'daily-pocard-quotes.jpeg';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
            .catch(function (error) {
                console.error('Error converting HTML to image:', error);
            });
    };

    const handleLikeClick = async () => {
        try {
          setClick(!isClick);
          if (!isClick) {
            // Add like
            await axios.post(`${apiUrl}/like`, { quote_id: quote.id });
           
          } else {
            // Remove like (You need to implement this endpoint in the backend)
            await axios.post(`${apiUrl}/unlike`, { quote_id: quote.id });
         
          }}
          catch (error) {
            console.error('Error updating like:', error);
          }
        };
    
    
    return (
        <>
            <body>
                <FrontendHeader />
                <div  className={style.contentSection}>
                <div className={style.cardName}>
        <p >#dailypocard</p>
      </div>
      
      {/* <span className={style.cardName}>#dailypocard</span> */}
                    <div ref={divRef} className={`${style.card} ${flipped ? style.flipped : ''}`} onClick={handleFlip} >
                    <div className={style.cardInner}>
                        
          <div className={style.cardFront}>
            
            <p>Click to reveal the message! </p>
          </div>
          <div className={style.cardBack}>
            <p>{quote.quote}</p>
            <div className={style.cardBackTitle}>#dailypocard</div>
          </div>
        </div>
      </div>
      {/* {flipped && lastFetchDate && (
        <p>Last fetched: {lastFetchDate}</p>
      )} */}    {flipped && (
                    <div className={style.btnsection}>
                        {/* <div className={style.iconWrapper}>
                    <Heart  isClick={isClick} onClick={handleLikeClick}      className={style.heartIcon}    /></div>
                   <div className={style.iconWrapper}>
                       <i class={`fa-regular fa-circle-down ${style.downloadButton}`} onClick={handleDownload}></i>
                       </div> */}

<div className={style.btnsection}>
                <Heart isClick={isClick} className={style.heartIcon} onClick={handleLikeClick}  />
                <img src='/images/down-load-grey.svg' className={style.downloadButton} onClick={handleDownload} ></img>
            </div>

                    </div>)}<br/>
                    <div>
                        <span className={style.staticMessage}>"Daily Positivity: Closer to Your Heart!"</span>
                    </div>
                   </div> 
            </body>
        </>
    );
}
