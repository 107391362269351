import React, { useState } from 'react';
import axios from 'axios';
import FrontendHeader from './FrontendHeader';
import validation from './AdminValidation'
import {useNavigate}  from 'react-router-dom'; 
import style from '../CSS/Admin.module.css';

export default function Admin() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [values, setValues] = useState({
    username: '',
    password: ''
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState('');
 
  const handleInput = (event) => {
    setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const err = validation(values); 
    console.log(err)
    setErrors(err);
    if (err.username === "" && err.password === "") {
      axios.post(`${apiUrl}/login`, values)
        .then(res => {
          if (res.data.errors) {
            setBackendError(res.data.errors);
          } else {
            setBackendError('');
            if (res.data.message === "Login successful") {
              const { token, fullName } = res.data;
              //console.log('Setting token and userName:', token, fullName);
              // Store token and user's name in localStorage
              localStorage.setItem('token', token);
              localStorage.setItem('userName', fullName);
              // navigate('/adminHomePage');
              setTimeout(() => {
                navigate('/adminHomePage');
              }, 100); 
            } else {
              setBackendError('Login failed');
            }
          }
        })
        .catch(err => console.log(err));
    }
  };

  return (
   
     <body><FrontendHeader/>
         <br/>
          <div  className={style.mainContainer}>
    <div className={style.main}>
    <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">Username</label>
            <input type="text" className="form-control" id="username" name="username" value={values.username} onChange={handleInput} />
            {errors.username && <div className="text-danger">{errors.username}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <input type="password" className="form-control" id="password" name="password" value={values.password} onChange={handleInput} />
            {errors.password && <div className="text-danger">{errors.password}</div>}
          </div>
          <button type="submit" className="btn btn-secondary">Login</button>
          {backendError && <span className="text-danger">{backendError}</span>}
        </form>
      </div>
    </div>
  </div></div></div></body>
);
}
