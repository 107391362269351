import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import {useState,useEffect} from 'react'
import style from '../CSS/Style.module.css'
export default function Header() {
  const navigate= useNavigate();
  const [userName,setUserName]=useState('');
  useEffect(() => {
    // Retrieve userName from localStorage
    const storedUserName = localStorage.getItem('userName');
    //console.log('Stored userName:', storedUserName);
    if (storedUserName) {
      setUserName(storedUserName);
      //console.log(userName)
    }
  }, []);

  const handleLogout=()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('userName')
  navigate('/admin')

  }
  return (
   
        <>
        <body>
         <div className={style.mainHeader}> 
          <div >
         
            <img src='/images/logosmall.png' className={style.logo}></img>
            
          
            </div><div>
     
     <p>Welcome, {userName}!</p>
     <button className='btn btn-secondary' onClick={handleLogout}>Logout</button>
    
   </div>
   </div>
   </body>
        </>
      
  )
}
