import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import {useState,useEffect} from 'react'
import style from '../CSS/Style.module.css'
export default function FrontendHeader() {
  
  return (
   
        <>
        <body>
       
          <div className={style.header}>
         
            <img src='/images/logosmall.png' className={style.logo}></img>
            
          
            </div>
   </body>
        </>
      
  )
}
