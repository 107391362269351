import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import style from "../CSS/Admin.module.css"
import Header from './Header';

const ManageQuote = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
 const navigate=useNavigate();
  const [quotes, setQuotes] = useState([]);
  const [quote, setQuote] = useState('');
 const [editId, setEditId] = useState(null);
 const [pageNumber,setPageNumber]=useState(1)
 const [totalPages, setTotalPages] = useState(0);

 
 const itemsPerPage = 10;
const handleClick=()=>{
  navigate('/adminHomePage')
}
  useEffect(() => {
    fetchQuotes();
  }, [pageNumber]);
  // useEffect(()=>{
  //   managePagination()
  // },[pageNumber])

  const fetchQuotes = async () => {
    try{
      const token = localStorage.getItem('token');
    const res = await axios.get(`${apiUrl}/allQuotes?pageNumber=${pageNumber}&limit=${itemsPerPage}`,  {
      headers: {
      Authorization: `Bearer ${token}`
     } })
    //console.log(res.data);
    setQuotes(res.data.quotes);
    setTotalPages(Math.ceil(res.data.total / itemsPerPage));
  }
  catch (error) {
    console.error('Error fetching quotes:', error);
  }}
  const updateQuote = async () => {
    if (editId) {
      await axios.put(`${apiUrl}/editQuote/${editId}`, {quote});
      setEditId(null);
      setQuote('');
     
      fetchQuotes();
    }
  };
  const deleteQuote = async (id) => {
    try {
      await axios.delete(`${apiUrl}/deleteQuote/${id}`);
      // Refresh the quotes list
      fetchQuotes();
    }
    catch (error) {
    console.error('Error deleting quote:', error);
    //   setError('Error deleting quote');
  }
  };

  const editQuote= (quote) => {
    setQuote(quote.quote);
   
    setEditId(quote.id);
  };
// const managePagination=async (num)=>{
//   await axios.get()
//   setPageNumber(num)

// }


  return (
    <body>
      <Header/>
    <div className={style.tableContainer}>
    <div className={style.tableContainerItem}>
      <h3>Quotes</h3>
      {editId && (
        <div>
          <h2>Edit Quote</h2><br/>
          <input
            type="textarea"
            value={quote}
            onChange={(e) => setQuote(e.target.value)}
            placeholder="Quote"
          />&nbsp;&nbsp;&nbsp;
         
          <button onClick={updateQuote}>Update Quote</button>
        </div>
      )}<br/><table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Quote</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
        </tr>
      </thead>
      <tbody>
        {/* <tr scope="row"> */}
        {quotes.map(quote => (
            <tr key={quote.id}>
              <td>{quote.id}</td>
              <td>{quote.quote}</td>
            
              <td>
                <button className="btn  btn-warning " onClick={() => editQuote(quote)}>Edit</button></td>
                <td><button className="btn  btn-danger "onClick={() => deleteQuote(quote.id)}>Delete</button>
              </td>
            </tr>
          ))}
          
        {/* </tr> */}
      </tbody>
    </table>
    <div>{Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className="btn btn-secondary me-3"
            onClick={() => setPageNumber(index + 1)}
          >
            {index + 1}
          </button>
          ))}</div>
          <br/>
           <button className="btn btn-primary" onClick={handleClick} type="button">Admin home page</button>
    </div>
   </div>
    </body>
  );
};

export default ManageQuote;
