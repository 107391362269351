import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './HTML/HomePage';
import Admin from './HTML/Admin'
import AdminHomePage from './HTML/AdminHomePage';
import ManageQuote from './HTML/ManageQuote';
function App() {
  return (
    <div className="App">
      <Router>
       <Routes>
  <Route path="/" element={<HomePage/> }/>
  <Route path="/admin" element={<Admin/>}/>
  <Route path="/adminHomePage" element={<AdminHomePage/>}/>
  <Route path="/manageQuote" element={<ManageQuote/>}/>
  </Routes>
  </Router>
       </div>
  );
}

export default App;
