import React,{useState,useEffect,useRef} from 'react'
import axios from 'axios'
import * as XLSX from 'xlsx';
import { Navigate, useNavigate } from 'react-router-dom'
import validateQuote from './AdminValidation'
import style from '../CSS/Style.module.css'
// import {Link} from 'react-router-dom'
import Header from './Header';
export default function AdminHomePage() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [userName,setUserName]=useState('');
  const [showModal, setShowModal] = useState(false);
  const [quoteText, setQuoteText] = useState('');
  const [error, setError] = useState({});
  const fileInputRef = useRef(null);
  const [file,setFile] = useState(null);

  const handleClick = () => 
{
   navigate('/manageQuote');
  };

  const handleAddQuote = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleManageQuote=()=>{

  }
  const handleSaveQuote = (e) => {
    if (!quoteText.trim()) {
      setError({ quoteText: 'Quote text cannot be empty' });
      alert("Quote text cannot be empty")
      return;
    }

    // Make a POST request to the backend endpoint to save the quote
    const token = localStorage.getItem('token');
    axios.post(`${apiUrl}/save-quote`, { quoteText })
      .then(response => {
       // console.log(response.data); // Log the response from the backend
        alert("Quote added sucessfully")
        setQuoteText('');
        setShowModal(false);
      })
      .catch(error => {
        console.error('Error saving quote:', error);
        alert("Error saving quote: " + error.message); 
      });}

      const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };
    
      const handleFileUpload = async () => {
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = async (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
    
          // // Add the current date and time to each row
          // const currentDatetime = new Date().toISOString();
          // const dataWithDatetime = json.map(row => ({
          //   ...row,
          //   dateandtime: currentDatetime
          // }));
    
          try {
            await axios.post(`${apiUrl}/upload`, json, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
            alert('Upload successful!');
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            setFile(null); // Clear file state
          } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file.');
          }
        };
        reader.readAsArrayBuffer(file);
      };
  return (
    
    <body>
      <Header/>
      
   <div className={style.tableContainer}>
     
   <div className={style.contentItems}>
    <div><button type="button" class="btn btn-secondary" onClick={handleAddQuote}>Add Quote</button><br/><br/>
   {/* <Link to="/manageQuote" className='Mangae'>Manage Quote</Link> */}

   <button type="button" onClick={handleClick} class="btn btn-secondary">Manage Quote</button><br/><br/>
    {/* <button type="button" class="btn btn-danger" onClick={handleManageQuote}>Manage Quote</button><br/><br/> */}
    <div>
    <p>Add Quotes by file upload(file format .xlsx, .xls)</p>
      <input type="file"  class="form-control-file" accept=".xlsx, .xls" onChange={handleFileChange} 
       ref={fileInputRef} />
      <button onClick={handleFileUpload} class="btn btn-secondary">Upload</button>
    </div>
    </div></div></div>
    {showModal && (
        <div className={style.modal} tabIndex="-1" role="dialog">
          <div className={style.modalDialog} role="document">
            <div className={style.modalContent}>
              <div className={style.modalHeader}>
                <h5 className={style.modalTitle}>Add Quote</h5>
                
              </div>
              <div className={style.modalBody}>
                <textarea
                  className="form-control"
                  value={quoteText}
                  name='quoteText'
                  onChange={(e) => setQuoteText(e.target.value)}
                  placeholder="Enter quote..."
                ></textarea>
              </div>
              <div className={style.modalFooter}>
                <button type="button" className="btn btn-primary" onClick={handleSaveQuote}>Save</button>&nbsp;&nbsp;
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
              </div>
            </div>
          </div>
        </div> 
      )}
    </body>
  )
}
